import { shuffle } from 'lodash';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Card } from 'src/components/atoms/Card';
import { Grid } from 'src/components/molecules/Grid';
import { Section, SectionHeader } from 'src/components/molecules/Section';
import { FixedImg } from 'src/embeds/image/image-dato';

export default function Drawer({ heading, callouts }) {
  const [randomCallouts, setCallouts] = useState(callouts);
  useEffect(() => {
    setCallouts((c) => shuffle(c));
  }, []);

  return (
    <Section maxWidth="large" centered>
      <SectionHeader>
        <h2>{heading}</h2>
      </SectionHeader>
      <Grid columns="1fr 1fr">
        {randomCallouts.map(({ id, bioNode, person }) => {
          const bio = bioNode?.childMarkdownRemark?.html || person?.bioNode?.childMarkdownRemark?.html;

          return (
            <Card key={id}>
              {!!person.image && <ProfileImage data={person.image} width={124} height={124} />}

              <h3>{person.name}</h3>

              <div key={id}>{!!bio && <div dangerouslySetInnerHTML={{ __html: bio }} />}</div>
            </Card>
          );
        })}
      </Grid>
    </Section>
  );
}

const ProfileImage = styled(FixedImg)`
  border-radius: 100%;
  margin-bottom: 1rem;
`;
